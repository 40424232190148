
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "exhibitionRewardItem",
  emits:['close'],
  props: {
    src: {
      type: String,
      default: "",
    },
    awardName: {
      type: String,
      default: ""
    },
    hint: {
      type: String,
      default: ""
    },

    target: {
      type: String,
      default: "",
    },
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props, ctx) {
    const router = useRouter();
    function onOpenPackage() {
      ctx.emit('close')
    }
    function close() {
       ctx.emit('close')
    }
    return {
      onOpenPackage,
      close
    };
  },
});
